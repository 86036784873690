import { Trans } from 'react-i18next';

import './savings-calculator-20k.scss';

import BrandLogo from './brand-logo';
import { formatEuros, formatEurosWithoutDecimals } from '../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { calculateStampFee } from './savings-calculator-util';

import React from 'react';

const MANUAL_VALUE_DEFAULT = null;

const calculateAgencyFees = value => {
  if (!value) return null;
  return value * 0.07;
};

class SavingsCalculator20K extends React.Component {
  constructor() {
    super();

    this.state = {
      isManualCalculationVisible: false,
      inputValue: MANUAL_VALUE_DEFAULT,
      stampFee20k: calculateStampFee(),
      taxRefundAgencyFee: calculateAgencyFees(MANUAL_VALUE_DEFAULT),
      isFeePercentage: false,
    };
  }

  toggleSection = () => {
    this.setState(
      prevState => {
        var isVisible = !prevState.isManualCalculationVisible;
        if (isVisible) document.getElementById('txtSavingsCalculatorManualInput').focus();

        return isVisible
          ? {
              isManualCalculationVisible: isVisible,
              inputValue: MANUAL_VALUE_DEFAULT,
              stampFee: null,
              stampFee20k: calculateStampFee(),
              taxRefundAgencyFee: calculateAgencyFees(MANUAL_VALUE_DEFAULT),
              isFeePercentage: false,
            }
          : {
              isManualCalculationVisible: isVisible,
              isFeePercentage: false,
              stampFee: 0,
            };
      },
      () => {
        // Adjust scroll to ensure the section is visible,
        if (this.state.isManualCalculationVisible) {
          const expandableSection = document.querySelector(
            '.st-c-savings-calculator20k__results-expandable'
          );
          const button = document.querySelector('.expandable-button');

          if (expandableSection && button) {
            const isMobile = window.innerWidth <= 768; // mobile breakpoint
            const offsetValue = {
              desktop: -400,
              mobile: 150,
            };
            const offset = isMobile ? offsetValue.mobile : offsetValue.desktop;

            const scrollTarget = isMobile
              ? expandableSection.getBoundingClientRect().top + window.scrollY - offset // Mobile: calculated from the top
              : expandableSection.getBoundingClientRect().bottom + window.scrollY + offset; // Desktop: calculated from the bottom

            window.scrollTo({
              top: scrollTarget,
              behavior: 'smooth',
            });
          }
        }
      }
    );
  };

  getFormattedInputValue = () => {
    const { inputValue } = this.state;
    return inputValue ? parseInt(inputValue, 10).toLocaleString('de-DE') : ''; // german format ensures compatibility with europe.
  };

  onInputChange = event => {
    let inputValue = event.target.value.replace(/\D/g, ''); // removes non digits

    if (inputValue === '') {
      this.setState({
        taxRefundAgencyFee: null,
        stampFee: null,
        inputValue: '',
        isFeePercentage: false,
      });
      return;
    }

    const numericValue = parseInt(inputValue, 10);

    //ignore values < 70
    if (numericValue < 70) {
      this.setState({
        taxRefundAgencyFee: null,
        stampFee: null,
        inputValue: inputValue, // Establece el valor solo con dígitos
        isFeePercentage: false,
      });
    } else {
      this.setState({
        taxRefundAgencyFee: calculateAgencyFees(numericValue),
        stampFee: calculateStampFee(numericValue),
        inputValue: inputValue, // Establece el valor solo con dígitos
        isFeePercentage: numericValue > 20000,
      });
    }
  };

  render() {
    return (
      <div className=" st-c-savings-calculator20k">
        <div className="st-c-savings-calculator20k__results">
          <div className="st-c-savings-calculator20k__manual_section">
            <button
              className={`button is-primary is-normal expandable-button ${
                this.state.isManualCalculationVisible ? 'is-expanded' : ''
              }`}
              onClick={this.toggleSection}
            >
              <Trans i18nKey="SavingsCalculator:Calculate20k"></Trans>
              <span className="icon">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </button>
          </div>
        </div>
        <div
          className={`st-c-savings-calculator20k__results-expandable ${
            this.state.isManualCalculationVisible ? 'slide-down' : 'slide-up'
          }`}
        >
          <div className={`st-c-savings-calculator20k__manual_section_form-container`}>
            <div className={`st-c-savings-calculator20k__manual_section_form `}>
              <label className="field-label">
                <Trans i18nKey="SavingsCalculator:InvoiceAmount"></Trans>
              </label>
              <div className="field">
                <div className="control">
                  <label className="input-manual-euro">€</label>
                  <input
                    id="txtSavingsCalculatorManualInput"
                    placeholder="20.001"
                    className="input is-large"
                    type="text"
                    maxLength="7"
                    value={this.getFormattedInputValue()}
                    onChange={this.onInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="st-c-savings-calculator20k__result">
            <div className="st-c-savings-calculator20k__label">
              <Trans i18nKey="SavingsCalculator:taxRefundFees"></Trans>
            </div>
            <div className="st-c-savings-calculator20k__price">
              <span className="st-c-savings-calculator20k__currency">€</span>
              {formatEurosWithoutDecimals(this.state.taxRefundAgencyFee)}
            </div>
          </div>
          <div className="st-c-savings-calculator20k__result is-stamp">
            <div className="st-c-savings-calculator20k__label self_top ">
              <Trans i18nKey="SavingsCalculator:with"></Trans>
              <BrandLogo className="st-c-savings-calculator20k__logo"></BrandLogo>
            </div>
            {
              <div className="st-c-savings-calculator20k__price">
                <span className="st-c-savings-calculator20k__currency">€</span>
                {formatEuros(this.state.stampFee)}
                {this.state.isFeePercentage && (
                  <div className="st-c-savings-calculator20k__price_sub">
                    (<Trans i18nKey="SavingsCalculator:OnePercent"></Trans>)
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SavingsCalculator20K;
